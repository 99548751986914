import React from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import PostList from '../components/postList'
import { Link } from "gatsby"
import styles from '../styles/index.module.css'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const PostText = (props) => {
    return <p className={styles.postText}>{props.children}</p>
}


const RecipeCollectionPage = ({data, pageContext, location}) => {
    let displayDescription = ''
    if(data.strapiCollectionOfRecipes.description && data.strapiCollectionOfRecipes.description.length > 0){
        displayDescription = data.strapiCollectionOfRecipes.description.split('\n').map((text) => {
            return <PostText>{text}</PostText>
        })
    }
    const disqusConfig = {
        url: `${'https://tastyappetite.net'+location.pathname}`,
        identifier: data.strapiCollectionOfRecipes.id,
        title: data.strapiCollectionOfRecipes.title
    }
    return(
        <div>
            <SEO title={data.strapiCollectionOfRecipes.title} canonicalPath={location.pathname}/>
            <h1 className={styles.collectionHeader}>{data.strapiCollectionOfRecipes.title}</h1>
            {displayDescription}
            <PostList data={data.strapiCollectionOfRecipes.posts} extension={'-collection'}/>
            <div className={styles.commentSection}>
                <CommentCount config={disqusConfig} placeholder={'...'} />
                <Disqus config={disqusConfig} />
            </div>
        </div>
    )
}

export const query = graphql`
  query($id: String!) {
    strapiCollectionOfRecipes(id: {eq: $id}) {
        id
        title
        websiteURL
        description
        created_at
        posts {
            title
            id
            websiteURL
            youtubeURL
            isRecipe
            nonVeg
            featureImage {
                childImageSharp {
                    fluid{
                        src
                        srcSet
                        presentationWidth
                        presentationHeight
                        base64
                        aspectRatio
                    }
                }
            }
        }
    }
  }
`

export default RecipeCollectionPage